.project__cards {
  padding: 4rem 3rem 3rem;
  background-color: #fff;
}

.project__cards > h3 {
  text-align: center;
  font-weight: 700;
  font-size: 30px;
  color: #2d2d2d;
}

.project__cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
}

.project__cards__wrapper {
  position: relative;
  margin-top: 2rem;
  width: 100%;
}

.project__cards__items {
  margin-bottom: 24px;
}

.portfolio-internal-link{
  text-decoration: none;
  font-weight: 600;
  color: #2d2d2d;
  font-family: "Montserrat", sans-serif;
  &:hover {
    color: #7dc853;
  }
}

@media screen and (max-width: 768px) {
  .project__cards {
    padding: 2rem;
  }

  .project__cards > h3 { 
    font-size: 28px;
  }
}

@media (max-width: 575px) { 
  .project__cards > h3 { 
    font-size: 24px;
  }
}

@media screen and (max-width: 375px) {
  .project__cards {
    padding: 1rem;
  }
}


@media only screen and (min-width: 1024px) {
  .project__cards__items {
    display: flex;
  }
}

