.notFound-container {
  background: url("https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/img-portfolio.webp")
    center center/cover no-repeat;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
  object-fit: contain;
  padding-top: 10rem;
  padding-bottom: 9rem;
}

.notFound-bottom {
  margin: 0;
  padding: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  overflow: hidden;
  bottom: -1px;
  transform: rotate(180deg);
  margin-top: -3rem;

  & > svg {
    display: block;
    height: 6vh;
    width: calc(120% + 1.3px);
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    fill: #fff;
  }
}

.notFound-content {
  padding: 4rem;
  color: #2d2d2d;
  line-height: 1.6;
  max-width: 1100px;
  margin: 0 auto;
  height: 80vh;
  > h2 {
    font-family: "Oswald", sans-serif;
    margin-bottom: 0.3rem;
    color: #4e4e4e;
    font-size: 50px;
    font-weight: 600;
    padding-left: 3rem;
    padding-right: 3rem;
    text-align: center;
  }
}

@media screen and (max-width: 960px) {
  .notFound-container {
    padding-top: 9rem;
    padding-bottom: 8rem;
  }

  .notFound-container > h1 {
    margin-top: -10px;
  }
}

@media screen and (max-width: 768px) {
  .notFound-container > h1 {
    font-size: 50px;
    margin-top: 0;
  }
  .notFound-content {
    padding: 3rem;
  }
}

@media screen and (max-width: 525px) {
  .notFound-container {
    padding-top: 8rem;
    padding-bottom: 7rem;

    > h1 {
      font-size: 40px;
    }
  }

  .notFound-content {
    padding: 2rem;
  }
}

@media screen and (max-width: 375px) {
  .notFound-container {
    padding-top: 7rem;
    padding-bottom: 6rem;
  }
}
