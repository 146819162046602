.carousel-component-container {
  background: url("https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/img-services.webp")
    center center/cover no-repeat;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
  object-fit: contain;
  padding-top: 12rem;
  padding-bottom: 3rem;

  > p {
    margin: 0 1.5rem 2rem;
    color: #fff;
    text-align: center;
    font-family: "Oswald", sans-serif;
    font-size: 22px;
    font-weight: 300;
    text-align: start;
    max-width: 1100px;
    line-height: 1.6;
  }
}

.svg-bottom {
  margin: 0;
  padding: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  overflow: hidden;
  bottom: -1px;
  transform: rotate(180deg);
  margin-top: -3rem;

  & > svg {
    display: block;
    height: 5vh;
    width: calc(120% + 1.3px);
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    fill: #fff;
    margin-bottom: 35px;
    top: 20px;
  }
}

/* Carousel section */

.carousel-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.carousel-frame {
  margin: auto;
  width: 80%;
  height: calc(16 / 9);
  max-height: 100vh;
}

.swiper-button-prev,
.swiper-button-next {
  background: transparent;
  color: #fff !important;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  color: #7dc853 !important;
}

.swiper-button-prev {
  left: 5% !important;
}

.swiper-button-next {
  right: 5% !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: "";
  font-size: 30px !important;
}

.swiper-pagination {
  color: #1b1b1c !important;
}

.swiper-pagination:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff !important;
}

.carousel-desc-header {
  margin: 1rem auto 2rem;
  padding: 0 2rem;
  text-align: center;
  font-family: "Oswald", sans-serif;
  /* font-size: 22px; */
  font-weight: 300;
  max-width: 1100px;
  line-height: 1.6;
}

/* .swiper-pagination-bullet {
  width: 20px !important;
  height: 20px !important;
} */

/* .swiper-pagination-bullet:hover {
  color: #7dc853 !important;
  background-color: #7dc853 !important;
  opacity: 1 !important;
} */

/* .swiper-pagination-bullet-active {
  background-color: #7dc853 !important;
  border: solid 3px #fff;
} */

.carousel-play {
  margin-top: 1rem;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.carousel-play:hover {
  color: #7dc853;
}

.carousel-description {
  max-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem auto 3rem;
  padding: 0 4rem;
  flex-direction: column;
  
  > h2 {
    padding-bottom: 1rem;
    font-weight: 300;
    font-family: "Oswald", sans-serif;
    margin-bottom: 1rem;
  }
}

.carousel-btns .btn {
  padding: 1rem 3rem;
  font-family: "Barlow Condensed", Helvetica, sans-serif;
  background: #ff4f15;
  border: none;
  border-bottom: 3px solid rgba(0, 0, 0, 0.14);
}

video {
  object-fit: contain;
  /* width: 20%; */
  height: 70vh;
  /* width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1; */
}

.close-btn-wrap {
  position: absolute;
  right: 17%;
  z-index: 100;
}

.close-carousel {
  color: #fff;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  z-index: 1;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -15px;
  margin-top: -15px;
  text-decoration: none;
}

.close-carousel span {
  transform: scale(2);
  margin-top: -5px;
}

.swiper-slide {
  height: 50vh;
  margin-top: auto;
  margin-bottom: auto;
}

.swiper__slide__img {
  display: flex;
  border-radius: 8px;
  margin: auto;
  max-height: 80%;
  max-width: 80%;
  max-height: 70vh;
}

@media screen and (max-width: 1024px) {
  .carousel-frame {
    width: 90%;
  }

  video {
    width: 100%;
  }

  .close-btn-wrap {
    right: 3.5%;
  }

  .swiper__slide__img {
    max-width: 100%;
  }
}

@media screen and (max-width: 960px) {
  .carousel-component-container {
    /* padding-top: 6rem; */
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .carousel-component-container {
    padding-top: 10rem;
  }

  .carousel-component-container > p {
    font-size: 24px;
  }

  .swiper-pagination-bullet {
    width: 15px !important;
    height: 15px !important;
  }
}

@media screen and (max-width: 525px) {
  .carousel-single-dot {
    width: 10px;
    height: 10px;
  }
}

@media screen and (max-width: 375px) {
  .carousel-description {
    padding: 0 2rem;
  }
}
