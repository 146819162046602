.principle-section {
  padding: 4rem;
  background: #fff;

  & > h3 {
    font-size: 30px;
    font-weight: 700;
    max-width: 1140px;
    margin: 0 auto;
  }
}

.principle-container {
  display: block;
  position: relative;
  padding: 32px 0 0;
  /* z-index: 1; */
}

.principle-wrapper {
  width: 100%;
  margin: 0 auto;
}

.principle-inner-container {
  display: flex;
  flex-wrap: wrap;
  /* margin-right: -15px; */
  /* margin-left: -15px; */
  justify-content: space-between;
}

.principle-left-side {
  position: relative;
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
}

.principle-right-side {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 100%;
  max-width: 100%;

  & > img {
    position: absolute;
    display: block;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
}

.principle-heading {
  margin-bottom: 1rem;
  text-align: center;

  > h2 {
    display: inline-block;
    font-size: 56px;
    font-weight: 700;
    text-transform: lowercase;
    line-height: 1em;
    letter-spacing: -1.6px;
  }
}

.principle-head {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #2d2d2d;
  margin-bottom: 1rem;
  line-height: 26px;
  margin-top: 1.5rem;
  text-align: left;
}

.principle-list-container {
  text-align: left;
}

.principle-list-item {
  list-style: none;
  display: flex;
  align-items: flex-start;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  transition: 2s all ease-in-out;
  opacity: 0;
}

.fade-item {
    transition: 2s all ease-in-out;
    opacity: 0;
}

.fadein {
    animation: fadeIn 0.9s 1;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.principle-icon-wrapper {
  padding-right: 1rem;
}

.principle-list-icon {
  color: #2d2d2d;
  font-size: 16px;

  > svg {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    vertical-align: -0.125em;
    width: 0.875em;
    overflow: visible;
  }
}

.principle-text-wrapper {
  flex: 1;
}

.principle-text {
  font-family: "Open Sans", sans-serif;
  color: #2d2d2d;
}

.principle-text span {
  font-weight: 600;
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
  }

  25% {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }

  50% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  75% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.up-img {
  width: 80%;
  left: 50px;
  animation: breathing 5s linear normal;
}

.down-img {
  width: 50%;
  bottom: -20px;
  right: -30px;
  animation: breathing 5s linear normal;
}

@media (min-width: 576px) {
  .principle-wrapper {
    max-width: 540px;
  }
}

@media (max-width: 767px) {
  .principle-section > h3 {
    font-size: 24px;
  }
  
  .principle-container {
    padding: 32px 16px 0;
  }
}

@media (min-width: 768px) {
  .principle-wrapper {
    max-width: 720px;
  }
}

@media (max-width: 991px) {
  .principle-section {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .principle-heading > h2 {
    font-size: 50px;
  }

  .principle-section > h3 {
    font-size: 22px;
  }
}

@media (min-width: 992px) {
  .principle-wrapper {
    max-width: 960px;
  }

  .principle-right-side {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .principle-left-side {
    flex: 0 0 50%;
    max-width: 50%;
    /* padding-left: 3rem; */
  }

  .principle-text-center {
    margin-top: 0;
    padding-top: 0;
  }
}

@media (max-width: 1199px) {
  .up-img {
    width: 85%;
    right: 0;
  }

  .down-img {
    width: 80%;
  }
}

@media (max-width: 1059px) {
  .up-img {
    width: 90%;
    left: 0;
  }

  .down-img {
    width: 70%;
    bottom: 0;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .principle-wrapper {
    max-width: 1140px;
  }
}

@media (max-width: 575px) {
  .principle-heading > h2 {
    font-size: 42px;
  }

  .principle-list-item {
    margin-left: -32px;
  }
}
