.choose-section {
  padding: 4rem;
  background: #fff;

  & > h3 {
    color: #2d2d2d;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }
}

.choose-container {
  display: block;
  position: relative;
  padding: 50px 0 0;
  /* z-index: 1; */
}

.choose-wrapper {
  width: 100%;
  margin: 0 auto;
}

.choose-inner-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.choose-left-side {
  position: relative;
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.choose-right-side {
  position: relative;
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.choose-text-center {
  text-align: center;
}

.choose-content-heading {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-weight: 300;
  color: #2d2d2d;
  margin-bottom: 1rem;
  line-height: 1.5;
  margin-top: 0;
  text-align: left;
}

.choose-list-container {
  text-align: left;
}

.choose-list-item {
  list-style: none;
  display: flex;
  align-items: flex-start;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.choose-list-item:hover {
  .choose-text-title {
    color: #7dc853;
    transition: all ease-in 0.2s;
  }
}

.choose-icon-wrapper {
  padding-right: 1rem;
}

.choose-list-icon {
  color: #7dc853;
  font-size: 18px;
}

.choose-text-wrapper {
  flex: 1;
  position: relative;
}

.choose-text-title {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 0.5rem;
}

.choose-text {
  font-weight: 300;
  line-height: 1.5;
  font-size: 15px;
}

.service-list-btn {
  background: #fff;
  color: #7dc853;
  border: none;
  cursor: pointer;
}

.service-list {
  margin-top: 0.5rem;
  font-weight: 500;
  line-height: 1.5;
  font-size: 14px;
  text-decoration: none;
  list-style: none;
}

.close-up-btn {
  background: transparent;
  border: none;
  position: absolute;
  right: 3rem;
  bottom: 0;
  font-size: 1.5rem;
  color: #7dc853;
  cursor: pointer;
}

.choose-text-save {
  font-weight: 300;
  line-height: 1.5;
  font-size: 14px;
  margin-top: 0.5rem;
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
  }

  25% {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }

  50% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  75% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.choose-img {
  height: 100%;
  object-fit: cover;
  object-position: 75% top;
  width: 100%;
  animation: breathing 5s linear normal;
}

.choose-bottom {
  margin: 0;
  padding: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  overflow: hidden;
  transform: rotate(360deg);

  & > svg {
    display: block;
    height: 5vh;
    width: calc(120% + 1.3px);
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    fill: #d5d5d3;
  }
}

.service-internal-link {
  text-decoration: none;
  font-weight: 600;
  color: #2d2d2d;
  font-family: "Montserrat", sans-serif;
  &:hover {
    color: #7dc853;
  }
}

@media (max-width: 1023px) {
  .choose-section {
    padding-bottom: 4rem;
  }

  .choose-right-side {
    margin-top: 1rem;
  }
}

@media (max-width: 768px) {
  .choose-container {
    padding: 32px 16px 0;
  }

  .choose-section {
    padding: 2rem 3rem;
  }

  .choose-section > h3 {
    font-size: 28px;
  }

  .choose-text-title,
  .choose-text {
    font-size: 13px;
  }
}

@media (max-width: 991px) {
  .choose-content-heading > h2 {
    font-size: 50px;
  }

  .choose-container {
    padding-top: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .choose-left-side {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .choose-right-side {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 3rem;
  }

  .choose-text-center {
    margin-top: 0;
    padding-top: 0;
  }
}

@media (max-width: 575px) {
  .choose-content-heading > h2 {
    font-size: 42px;
  }

  .choose-list-item {
    margin-left: -32px;
  }

  .close-up-btn {
    right: 0;
  }

  .service-list {
    font-size: 12px;
  }

  .choose-section > h3 {
    font-size: 24px;
  }
}
