.navbar {
  /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
  background: transparent;
  /* height: 80px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
}

.navbar-scroll {
  background-color: #fff;
  transition: background-color 0.3s ease-out;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* z-index: 100; */

  > span {
    display: block;
    font-weight: 500;
  }
}

.navbar-logo-scroll {
  color: #2d2d2d;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
  height: 80px;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-size: 15px;
}

.nav-links-scroll {
  color: #2d2d2d;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.nav-links.active {
  color: #7dc853;
  font-weight: 600;
  font-size: 18px;
}

.promo-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0;
  height: 100%;
  font-size: 15px;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  justify-content: center;
}

.promo-links-scroll {
  background-color: #ff4f15;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.promo-text {
  font-family: "Inter", sans-serif;
  /* padding-top: 1rem;
  padding-bottom: 1rem; */
  display: flex;
  justify-content: center;
  align-items: center;

  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: promo-text 15s linear infinite;
  -webkit-animation: promo-text 15s linear infinite;
  animation: promo-text 18s linear infinite;
}

.promo-text > p {
  overflow: hidden;
  width: max-content;
  font-weight: 600;
  font-size: 30px;
}

@-moz-keyframes promo-text {
  0% {
    -moz-transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
  }
}
@-webkit-keyframes promo-text {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}
@keyframes promo-text {
  0% {
    -moz-transform: translateX(100%); /* Firefox bug fix */
    -webkit-transform: translateX(100%); /* Firefox bug fix */
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%); /* Firefox bug fix */
    -webkit-transform: translateX(-100%); /* Firefox bug fix */
    transform: translateX(-100%);
  }
}

.promo-img {
  margin-right: 1rem;
  height: 90px;
}

.fa-bars {
  color: #fff;
}

.fa-bars-scroll {
  color: #000;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.truReno-logo {
  width: 32px;
  height: 32px;
  /* margin-left: 6px; */
}

@media screen and (max-width: 1440px) {
  .promo-text {
    transform: translateX(105%);
    animation: promo-text 18s linear infinite;
  }

  @keyframes promo-text {
    0% {
      transform: translateX(105%);
    }
    100% {
      transform: translateX(-105%);
    }
  }
}

@media screen and (max-width: 1200px) {
  .promo-text {
    transform: translateX(95%);
    animation: promo-text 18s linear infinite;
  }

  @keyframes promo-text {
    0% {
      transform: translateX(95%);
    }
    100% {
      transform: translateX(-95%);
    }
  }
}

@media screen and (max-width: 1024px) {
  .promo-text {
    transform: translateX(90%);
    animation: promo-text 18s linear infinite;
  }

  .promo-text > p {
    font-size: 28px;
  }

  @keyframes promo-text {
    0% {
      transform: translateX(90%);
    }
    100% {
      transform: translateX(-90%);
    }
  }
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 92vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: flex-start;
    /* below margin-top is used only when promo text exists */
    margin-top: 90px;
  }

  /* .nav-menu-mobile {
    position: fixed !important;
    overflow-y: scroll;
    max-height: 100vh;
    height: 100vh;
    width: 100%;
    top: 0px;
  } */

  .nav-menu.active {
    background: #2d2d2d;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    /* z-index: 1; */
    opacity: 0.99;
    /* height: 100vh; */
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #2d2d2d;
    border-radius: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    /* z-index: 100; */
  }

  .fa-xmark {
    color: #fff;
    font-size: 2rem;
  }

  .fa-xmark-scroll {
    color: #000;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #2d2d2d;
    transition: 250ms;
  }

  .navbar-logo {
    position: absolute;
    left: 5%;
  }
}

@media screen and (max-width: 768px) {
  .navbar-logo {
    > span {
      font-size: 26px;
    }
  }

  .promo-text {
    transform: translateX(85%);
    animation: promo-text 18s linear infinite;
  }

  @keyframes promo-text {
    0% {
      transform: translateX(85%);
    }
    100% {
      transform: translateX(-85%);
    }
  }

  .promo-text > p {
    font-size: 24px;
  }

  .promo-img {
    height: 60px;
  }

  .nav-menu {
    /* below margin-top is used only when promo text exists */
    margin-top: 60px;
  }
}

@media screen and (max-width: 425px) {
  .promo-text {
    transform: translateX(70%);
    animation: promo-text 18s linear infinite;
  }

  .promo-text > p {
    font-size: 22px;
  }

  @keyframes promo-text {
    0% {
      transform: translateX(70%);
    }
    100% {
      transform: translateX(-70%);
    }
  }
}

@media screen and (max-width: 375px) {
  .navbar-logo {
    > span {
      font-size: 22px;
    }
  }

  .truReno-logo {
    width: 28px;
    height: 28px;
    margin-left: 6px;
  }

  .promo-text {
    transform: translateX(65%);
    animation: promo-text 18s linear infinite;
  }

  @keyframes promo-text {
    0% {
      transform: translateX(65%);
    }
    100% {
      transform: translateX(-65%);
    }
  }
}
