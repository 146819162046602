.mission-section {
  padding: 4rem;
  padding-top: 2rem;
  /* background: #dfdfdf; */

  & > h3 {
    color: #2d2d2d;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }
}

.mission-container {
  display: block;
  position: relative;
  padding: 2rem 0 0;
}

.mission-wrapper {
  width: 100%;
  margin: 0 auto;
}

.mission-inner-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.mission-inner-container:last-of-type {
  margin-bottom: 0;
}

.container-img-side {
  position: relative;
  flex: 0 0 100%;
  max-width: 100%;
  height: 350px;
  padding: 1rem;
  border-top: #8b8b8b 1px dashed;
}
.img-side-last {
  height: 100%;
}

.container-text-side {
  position: relative;
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  border-left: dashed 1px #8b8b8b;
}

.content-text-center {
  text-align: center;
}

.content-heading {
  margin-bottom: 1rem;
  text-align: center;
}

.content-head {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #2d2d2d;
  margin-bottom: 1rem;
  line-height: 26px;
  text-align: left;
}

.content-list-container {
  text-align: left;
}

.content-list-item {
  list-style: none;
  display: flex;
  align-items: flex-start;
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
}

.content-list-item:hover {
  .content-list-icon, .content-text-title {
    color: #7dc853;
    transition: all ease-in 0.2s;
  }
}

.content-icon-wrapper {
  padding-right: 1rem;
}

.content-list-icon {
  color: #2d2d2d;
  font-size: 16px;

  > svg {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    vertical-align: -0.125em;
    width: 0.875em;
    overflow: visible;
  }
}

.content-text-wrapper {
  flex: 1;
  position: relative;
}

.content-text-title {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 0.5rem;
}

.content-text {
  font-weight: 300;
  line-height: 1.5;
  font-size: 15px;
}

.workText {
  font-size: 1.1rem;
}

.mission-subtitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  text-align: center;
  margin-top: 1rem;
  font-size: 22px;
}

.about-us-internal-link {
  text-decoration: none;
  font-weight: 600;
  color: #2d2d2d;
  font-family: "Montserrat", sans-serif;
  &:hover {
    color: #7dc853;
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
  }

  25% {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }

  50% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  75% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.mission-up-img {
  object-fit: cover;
  object-position: 50% center;
  transition: all 0.2s linear;
  width: 100%;
  height: 100%;
  /* animation: breathing 5s linear normal; */
}

.mission-down-img {
  position: absolute;
  display: block;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
  width: 50%;
  bottom: -40px;
  right: 0;
  animation: breathing 5s linear normal;
}

.mission-bottom {
  margin: 0;
  padding: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  overflow: hidden;
  transform: rotate(360deg);

  & > svg {
    display: block;
    height: 5vh;
    width: calc(120% + 1.3px);
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    fill: #d5d5d3;
  }
}

@media (min-width: 576px) {
  .mission-wrapper {
    max-width: 540px;
  }
}

@media (max-width: 1024px) {
  .mission-section {
    padding-bottom: 4rem;
  }
}

@media (max-width: 767px) {
  .mission-container {
    padding: 32px 16px 0;
  }

  .mission-section {
    padding: 2rem 3rem;
  }

  .mission-section > h3 {
    font-size: 28px;
  }
}

@media (min-width: 768px) {
  .mission-wrapper {
    max-width: 720px;
  }
}

@media (max-width: 991px) {
  .mission-section {
    padding-top: 0;
  }
  .content-heading > h2 {
    font-size: 50px;
  }

  .mission-container {
    padding-top: 0;
  }

  .mission-subtitle {
    margin-bottom: 1rem;
  }

  .mission-inner-container {
    margin-bottom: 0;
  }

  .container-text-side {
    border-left: none;
  }
}

@media (min-width: 992px) {
  .mission-wrapper {
    max-width: 960px;
  }

  .container-img-side {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .container-text-side {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 3rem;
  }

  .content-text-center {
    margin-top: 0;
    padding-top: 0;
  }
}

@media (max-width: 575px) {
  .content-heading > h2 {
    font-size: 42px;
  }

  .content-list-item {
    margin-left: -32px;
  }

  .mission-section > h3 {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  .mission-wrapper {
    max-width: 1140px;
  }
}
