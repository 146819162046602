.policy-container {
  background: url("https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/img-portfolio.webp") center center/cover
    no-repeat;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
  object-fit: contain;
  padding-top: 10rem;
  padding-bottom: 9rem;
}

.policy-container > h1 {
  color: #fff;
  font-size: 65px;
  text-transform: uppercase;
  text-shadow: 5px 5px rgba(81, 83, 81, 1);
  margin-top: 1rem;
  margin-bottom: 50px;
  padding: 0 1rem;
}

.policy-bottom {
  margin: 0;
  padding: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  overflow: hidden;
  bottom: -1px;
  transform: rotate(180deg);
  margin-top: -3rem;

  & > svg {
    display: block;
    height: 6vh;
    width: calc(120% + 1.3px);
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    fill: #fff;
  }
}

.policy-content {
  padding: 4rem;
  color: #2d2d2d;
  line-height: 1.6;
  max-width: 1100px;
  margin: 0 auto;
  > h2 {
    font-family: "Oswald", sans-serif;
    /* font-size: 22px; */
    margin-bottom: 0.3rem;
  }

  > p, ul {
    margin-bottom: 1rem;
  }

  > p, li {
    font-family: "Open Sans", sans-serif;
  }

  > ul {
    margin-left: 1rem;
  }
}

.privacy-contact-us-link {
  color: #000DEE;
}

@media screen and (max-width: 960px) {
  .policy-container {
    padding-top: 9rem;
    padding-bottom: 8rem;
  }

  .policy-container > h1 {
    margin-top: -10px;
  }
}

@media screen and (max-width: 768px) {
  .policy-container > h1 {
    font-size: 50px;
    margin-top: 0;
  }
  .policy-content {
    padding: 3rem;
  }
}

@media screen and (max-width: 525px) {
  .policy-container {
    padding-top: 8rem;
    padding-bottom: 7rem;

    > h1 {
      font-size: 40px;
    }
  }

  .policy-content {
    padding: 2rem;
  }
}

@media screen and (max-width: 375px) {
  .policy-container {
    padding-top: 7rem;
    padding-bottom: 6rem;
  }
}
