* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}


html {
  scroll-padding-top: 110px;
}