.cards__item {
  display: flex;
  flex: 1;
  margin: 0 2rem;
  border-radius: 5px;
  /* position is for safari browser to show the label */
  position: relative;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 5px;
  overflow: hidden;
  text-decoration: none;
}

.noLink {
  cursor: unset;
}

.cards__item__pic__wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.cards__item__pic__wrap__no__text {
  padding-top: 0;
  height: 100%;
}

/* .cards__item__pic__wrap::after, .cards__item__pic__wrap__no__text::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 8px 8px;
  max-width: calc((100%) - 60px);
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  background-color: #3e3e3e;
  box-sizing: border-box;
  border-radius: 3px 3px 0 0;
} */

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img__without__text {
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover, .cards__item__img__without__text:hover  {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 50px;
  background: #fff;
}

.cards__item__text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
}

.card__label {
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 8px 8px;
  max-width: calc((100%) - 60px);
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  background-color: #3e3e3e;
  box-sizing: border-box;
  border-radius: 3px 3px 0 0;
}

@media screen and (max-width: 1200px) {
  .cards__item {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .cards__item__info {
    padding: 20px 12px 50px;
  }
}

@media screen and (max-width: 500px) {
  .card__label {
    font-size: 14px;
  }
}

/* @media screen and (max-width: 425px) {
  .cards__item__pic__wrap::after, .cards__item__pic__wrap__no__text::after {
    font-size: 14px;
  }
} */