.about-container {
  /* background: url("https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/img-kitchen-hero.webp") center center/cover no-repeat; */
  background: url("https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/img-about-us.webp") center center/cover no-repeat;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.65);
  object-fit: contain;
  padding-top: 13rem;
  padding-bottom: 11rem;
}

.about-container > h1 {
  color: #fff;
  font-size: 34px;
  text-transform: uppercase;
  text-shadow: 3px 3px rgb(101, 104, 101);
  margin-top: 1rem;
  margin-bottom: 50px;
  padding: 0 1rem;
  max-width: 1100px;
  text-align: center;
}

.about-container > h2 {
  margin: 0 8px;
  color: #fff;
  text-shadow: 3px 3px rgb(101, 104, 101);
  font-size: 32px;
  font-weight: 300;
  padding-left: 3rem;
  padding-right: 3rem;
  text-align: center;
  max-width: 1100px;
}

.about-btns {
  margin-top: 4rem;
}

.about-btns .btn {
  margin: 6px;
  font-family: "Barlow Condensed", Helvetica, sans-serif;
}

.about-bottom {
  margin: 0;
  padding: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  overflow: hidden;
  bottom: -1px;
  transform: rotate(180deg);
  margin-top: -3rem;

  & > svg {
    display: block;
    height: 6vh;
    width: calc(120% + 1.3px);
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    fill: #fff;
  }
}

.about__cards {
  padding: 3rem;
  background: #fff;

  & > h1 {
    color: #2d2d2d;
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
  }
}

.about__cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
}

.about__cards__wrapper {
  position: relative;
  margin: 50px 0 0;
}

.about__cards__items {
  margin-bottom: 24px;
}

@media screen and (max-width: 1024px) {
  .about-container > h1 {
    font-size: 32px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    max-width: 900px;
  }

  .about-container > h2 {
    font-size: 28px;
    max-width: 800px;
  }
}

@media screen and (max-width: 960px) {
  .about-container {
    padding-top: 11rem;
    padding-bottom: 12rem;
  }

  .about-container > h1 {
    font-size: 30px;
  }

  .about-container > h2 {
    font-size: 26px;
  }
}

@media screen and (max-width: 768px) {
  .about-container > h1 {
    font-size: 28px;
  }

  .about-container > h2 {
    font-size: 24px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
    font-weight: 500;
  }
}

@media screen and (max-width: 525px) {
  .about-container {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .about-container > h1 {
    font-size: 26px;
    line-height: 1.3;
  }

  .about-container > h2 {
    font-size: 22px;
  }
}

@media screen and (max-width: 375px) {
  .about-container {
    padding-top: 10rem;
    padding-bottom: 8rem;
  }

  .about__cards {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .about-container > h1 {
    font-size: 24px;
  }

  .about-container > h2 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1024px) {
  .about__cards__items {
    display: flex;
  }
}
