.hero-container {
  /* background: url("https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/img-hero.webp") center center/cover no-repeat; */
  background: url("https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/img-hero.webp") center center/cover no-repeat;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.55);
  object-fit: contain;
  padding-top: 13rem;
  padding-bottom: 18rem;
}

.hero-container > h1 {
  color: #fff;
  font-size: 34px;
  text-transform: uppercase;
  text-shadow: 3px 3px rgb(101, 104, 101);
  margin-top: 1rem;
  margin-bottom: 50px;
  padding: 0 1rem;
  max-width: 1100px;
  text-align: center;
}

.hero-container > h2 {
  margin: 0 8px;
  color: #fff;
  text-shadow: 3px 3px rgb(101, 104, 101);
  font-size: 32px;
  font-weight: 300;
  padding-left: 3rem;
  padding-right: 3rem;
  text-align: center;
  max-width: 1100px;
}

.fa-circlePlay {
  margin-left: 6px;
}

.hero-btns {
  margin-top: 4rem;
}

.hero-btns .btn {
  margin: 6px;
  font-family: "Barlow Condensed", Helvetica, sans-serif;
}

.hero-bottom {
  margin: 0;
  padding: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  overflow: hidden;
  transform: rotate(180deg);
  margin-top: -50px;

  & > svg {
    display: block;
    height: 6vh;
    width: calc(120% + 1.3px);
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    fill: #fff;
  }
}

@media screen and (max-width: 1024px) {
  .hero-container > h1 {
    font-size: 32px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    max-width: 900px;
  }

  .hero-container > h2 {
    font-size: 28px;
    max-width: 800px;
  }
}

@media screen and (max-width: 960px) {
  .hero-container {
    /* padding-top: 11rem; */
    padding-bottom: 13rem;
  }

  .hero-container > h1 {
    font-size: 30px;
  }

  .hero-container > h2 {
    font-size: 26px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 28px;
  }

  .hero-container > h2 {
    font-size: 24px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
    font-weight: 500;
  }
}

@media screen and (max-width: 525px) {
  .hero-container {
    padding-top: 11rem;
    padding-bottom: 9rem;
  }

  .hero-container > h1 {
    font-size: 26px;
    line-height: 1.3;
  }

  .hero-container > h2 {
    font-size: 22px;
  }
}

@media screen and (max-width: 375px) {
  .hero-container {
    padding-top: 10rem;
    padding-bottom: 6rem;
  }

  .hero-container > h1 {
    font-size: 24px;
  }

  .hero-container > h2 {
    font-size: 20px;
  }
}
