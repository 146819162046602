.contact-section-container {
  background: #fff;
  padding: 2rem;
}

.contact-section-wrapper {
  margin: 0 auto;
  text-align: center;
  max-width: 1500px;
}

.contact-section-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > h2 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 1rem;
    max-width: 1100px;
    padding: 0 1rem;
    text-align: left;
  }

  & > p {
    font-family: "Open Sans", sans-serif;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
}

.contact-section-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact-section-text {
  font-size: 18px;
  text-rendering: auto !important;
  width: 100%;
  max-width: 100% !important;
}

.contact-section-form {
  width: 100%;
}

.text-email-wrapper,
.text-phone-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #ececee;
  max-width: 504px;
  margin: 0 auto;
}

.icon-email,
.icon-phone {
  padding: 1rem 1rem 1rem 0.5rem;
  font-size: 2rem;
  color: #3e3e3e;
}

.text-email p:nth-of-type(2),
.text-phone p:nth-of-type(2) {
  font-weight: 600;
}

.main-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-width: 504px;

  /* & > div:first-of-type {
    margin-top: 0;
  } */
}

.input-wrapper {
  width: 100%;
  position: relative;
  margin-top: 12px;

  & > label:first-of-type {
    margin-top: 0;
  }
}

.input-label {
  line-height: 2;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 4px;
  font-size: 14px;
}

.input-field,
.textarea-field {
  height: 48px;
  width: 100%;
  border: 1px solid #ececee;
  border-radius: 4px;
  background-color: #fff;
  padding: 12px 8px;
  font-size: 16px;

  &::placeholder {
    font-size: 16px;
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px #e6f9fe, 0px 0px 0px 3px #005587;
  }
}

select {
  color: #4a4a4a;
}

.textarea-field {
  height: auto;
  margin-bottom: 1rem;
}

.input-error {
  border: 1px solid #c81414;
}

.error-msg {
  color: #c81414;
  margin-left: 0.5rem;
}

.error-wrapper {
  display: flex;
  width: 100%;
  padding-top: 8px;
  justify-content: flex-start;
  align-items: center;
}

.submit-btn {
  height: 48px;
  width: calc(375px - 64px);
  border: 0;
  border-radius: 4px;
  background-color: #ff4f15;
  padding: 12px 8px;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
  border-bottom: 3px solid rgba(0, 0, 0, 0.14);
  font-weight: 600;
  outline: none;
}

.notification-wrapper {
  position: absolute;
  width: 90%;
}

.notification {
  width: 105%;
  background: #fff;
  border-radius: 6px;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -100%) scale(0.1);
  text-align: center;
  padding: 0 30px 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  visibility: hidden;
  transition: transform 0.4s, top 0.4s;

  & > h2 {
    margin: 15px 0 10px;
  }

  & > p {
    color: #4a4a4a;
    font-size: 14px;
  }

  & > button {
    width: 100%;
    margin-top: 20px;
    padding: 10px 0;
    background: #4a4a4a;
    color: #fff;
    border: 0;
    outline: none;
    font-size: 18px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 4px 3px rgba(0, 0, 0, 0.2);
  }
}

.notification-open {
  visibility: visible;
  top: 50%;
  transform: translate(-50%, -100%) scale(1);
}

.icon-tick {
  position: relative;
  font-size: 4rem;
  margin-top: -40px;
}

.spinner {
  position: absolute;
  font-size: 4rem;
  top: 30%;
}

.recaptcha {
  margin-bottom: 1rem;
}

.swal2-title {
  padding: 0;
}

.swal2-icon {
  margin-top: 1.5rem;
}

.swal2-styled {
  background-color: #4a4a4a !important;
  box-shadow: none !important;
}

.text-img {
  display: none;
}

@media screen and (max-width: 375px) {
  .submit-btn {
    width: calc(320px - 64px);
  }
}

@media screen and (min-width: 768px) {
  .contact-section-content {
    flex-direction: row;
  }

  .contact-section-form {
    padding: 1rem 2rem;
  }

  .contact-section-text {
    max-width: 350px;
    padding: 0 2rem;
  }

  .text-img {
    max-width: 350px;
    display: flex;
    margin: 0 auto;
    > img {
      width: 100%;
      margin-top: 2rem;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
  }

  .text-email-wrapper,
  .text-phone-wrapper {
    max-width: 350px;
    margin: 0 auto;
  }

  .contact-section-heading {
    > h2 {
      margin-bottom: 0;
      font-size: 24px;
    }
  }

  .recaptcha {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .contact-section-heading {
    > h2 {
      font-size: 26px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .text-email-wrapper,
  .text-phone-wrapper {
    max-width: 500px;
  }

  .text-img {
    max-width: 500px;
      > img {
        height: 400px;
        position: relative;
        object-fit: cover;
      }
    }

    .contact-section-heading {
    > h2 {
      font-size: 28px;
    }
  }
}
